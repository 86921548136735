import React from "react";
import Card, { CardProps } from "@mui/material/Card";
import VerticalLogoIcon from "../../icons/VerticalLogo";

interface Props extends CardProps {
  children: React.ReactNode;
  isLarge?: boolean;
  isSmall?: boolean;
}

const LoginCard: React.FC<Props> = ({
  children,
  isLarge,
  isSmall,
  ...rest
}) => {
  const propsSX = rest?.sx ? { ...rest.sx } : {};
  const cardWidth = () => {
    if (isSmall) return 400;
    if (isLarge) return 500;
    return 450;
  };
  return (
    <Card
      {...rest}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        p: 10,
        maxWidth: { xs: "100%", sm: cardWidth() },
        ...propsSX,
      }}
    >
      <VerticalLogoIcon />
      {children}
    </Card>
  );
};

export default LoginCard;
