import { Auth } from "aws-amplify";
import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../routing";
import LoadingCircle from "./LoadingCircle";
import AuthContext from "../authContext";
import { MFA_APP_CHALLENGE_NAME } from "../AuthProvider";

interface RequireMFACheckProps {
  children: React.ReactNode;
}

const PUBLIC_ROUTES = [
  `/${AppRoutes.Login}`,
  `/${AppRoutes.ResetPassword}`,
  `/${AppRoutes.Register}`,
];

const MFA_VERIFICATION_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

const RequireMFACheck = ({ children }: RequireMFACheckProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);
  const { cognitoUser } = useContext(AuthContext);

  console.log("MFA boundary hit");

  useEffect(() => {
    checkMFARequirement();
  }, [location.pathname, cognitoUser]); // Re-run when path or cognito user changes

  const checkMFARequirement = async () => {
    // Don't check MFA for public routes
    if (PUBLIC_ROUTES.includes(location.pathname)) {
      setIsChecking(false);
      return;
    }

    try {
      // Check if user is authenticated
      const session = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();

      // Check if user needs to verify MFA
      if (
        cognitoUser &&
        (cognitoUser as any).challengeName === MFA_APP_CHALLENGE_NAME
      ) {
        try {
          // Check if this device has been remembered
          const preferredMFA = await Auth.getPreferredMFA(user);
          console.log("Current preferred MFA:", preferredMFA);
          console.log("Session validity:", session.isValid());

          // If the preferred MFA is SOFTWARE_TOKEN_MFA and we have a valid session,
          // we can assume the device is remembered
          if (preferredMFA === "SOFTWARE_TOKEN_MFA" && session.isValid()) {
            console.log("Device is remembered, bypassing MFA");
            // Clear the MFA challenge from the cognito user
            (cognitoUser as any).challengeName = null;
            setIsChecking(false);
            return;
          }
        } catch (error) {
          console.error("Error checking MFA status:", error);
        }

        console.log("MFA verification required");
        navigate(`/${AppRoutes.Login}`, {
          state: {
            returnTo: location.pathname,
            message: "Please verify your MFA code to continue.",
          },
          replace: true,
        });
      }
    } catch (error) {
      // Handle various auth errors
      if (error instanceof Error && error.name === "NoSessionError") {
        // User isn't logged in, redirect to login
        if (!PUBLIC_ROUTES.includes(location.pathname)) {
          navigate(`/${AppRoutes.Login}`, {
            state: { returnTo: location.pathname },
            replace: true,
          });
        }
      } else {
        console.error("Auth check error:", error);
        // Optionally redirect to error page or show error message
      }
    } finally {
      setIsChecking(false);
    }
  };

  if (isChecking) {
    return <LoadingCircle />;
  }

  return <>{children}</>;
};

export default RequireMFACheck;
