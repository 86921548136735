import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoginCard from "./LoginCard";
import { AuthMessage } from "../../util/copy";
import { QRCodeSVG } from "qrcode.react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../routing";

interface SetupMFAProps {
  onSetupComplete: () => void;
  onCancelSetup?: () => void;
}

type EntryMethod = "qr" | "manual";

const SetupMFA = ({ onSetupComplete, onCancelSetup }: SetupMFAProps) => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [setupComplete, setSetupComplete] = useState(false);
  const [entryMethod, setEntryMethod] = useState<EntryMethod>("qr");

  const formatSecretCode = (code: string) => {
    return code.match(/.{1,4}/g)?.join(" ") || code;
  };

  useEffect(() => {
    setupTOTP();
  }, []);

  const handleBackToLogin = async () => {
    try {
      // Call the cancel setup callback if provided
      if (onCancelSetup) {
        onCancelSetup();
      }

      await Auth.signOut();
      // Clear any cached data
      await (Auth as any)._storage.clear();
      // Force a page reload to ensure clean state
      window.location.href = `/${AppRoutes.Login}`;
    } catch (err) {
      console.error("Error signing out:", err);
    }
  };

  const setupTOTP = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const secret = await Auth.setupTOTP(user);
      setSecretCode(secret);
      const qrCodeContent = `otpauth://totp/AWS Amplify:${user.username}?secret=${secret}&issuer=AWS Amplify`;
      setQrCode(qrCodeContent);
    } catch (err) {
      console.error("Error setting up TOTP:", err);
      setError(AuthMessage.MFAVerificationFailed);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // Prevent all default form behavior
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    setError("");
    setIsLoading(true);

    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("Starting MFA setup process...");
      console.log("Current user:", user);

      try {
        console.log("Verifying TOTP token...");
        await Auth.verifyTotpToken(user, code);
        console.log("TOTP token verified successfully");
      } catch (error: any) {
        console.error("Error verifying TOTP token:", error);
        setError("Failed to verify TOTP token: " + error.message);
        setIsLoading(false);
        return;
      }

      try {
        console.log("Setting preferred MFA to TOTP...");
        await Auth.setPreferredMFA(user, "TOTP");
        console.log("Preferred MFA set to TOTP");
      } catch (error: any) {
        console.error("Error setting preferred MFA:", error);
        setError("Failed to set preferred MFA: " + error.message);
        setIsLoading(false);
        return;
      }

      // Verify the MFA setup was successful
      try {
        const session = await Auth.currentSession();
        const claims = session.getIdToken().payload;
        console.log("Current session claims:", claims);

        // Force a token refresh to get updated claims
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        const freshSession = await Auth.currentSession();
        const freshClaims = freshSession.getIdToken().payload;
        console.log("Fresh session claims:", freshClaims);

        if (freshClaims.mfa_required === "true") {
          console.warn("MFA still required after setup. Claims:", freshClaims);
          setError("MFA setup did not clear mfa_required claim");
          setIsLoading(false);
          return;
        }
      } catch (error: any) {
        console.error("Error checking session claims:", error);
        setError("Failed to verify MFA setup: " + error.message);
        setIsLoading(false);
        return;
      }

      setSetupComplete(true);
      // Call the callback after a short delay to show the success message
      setTimeout(() => {
        onSetupComplete();
      }, 2000);
    } catch (error: any) {
      console.error("MFA setup error:", error);
      setError(error.message || AuthMessage.MFAVerificationFailed);
      setIsLoading(false);
    }
  };

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: EntryMethod
  ) => {
    setEntryMethod(newValue);
  };

  return (
    <LoginCard>
      <form onSubmit={handleSubmit} noValidate>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography variant="h6" textAlign="center">
            <strong>Set Up Multi-Factor Authentication</strong>
          </Typography>

          {!setupComplete ? (
            <>
              <Typography variant="body1" textAlign="center">
                1. Download an authenticator app (like Google Authenticator or
                Authy)
              </Typography>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={entryMethod}
                  onChange={handleTabChange}
                  aria-label="entry method tabs"
                >
                  <Tab label="QR Code" value="qr" />
                  <Tab label="Manual Entry" value="manual" />
                </Tabs>
              </Box>

              {entryMethod === "qr" ? (
                <>
                  <Typography variant="body1" textAlign="center">
                    2. Scan this QR code with your authenticator app
                  </Typography>
                  {qrCode && (
                    <Box display="flex" justifyContent="center" my={2}>
                      <QRCodeSVG value={qrCode} size={200} />
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Typography variant="body1" textAlign="center">
                    2. Enter this code manually in your authenticator app
                  </Typography>
                  <Box display="flex" justifyContent="center" my={2}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "monospace",
                        letterSpacing: "0.2em",
                        userSelect: "all",
                        whiteSpace: "pre-wrap",
                        textAlign: "center",
                      }}
                    >
                      {formatSecretCode(secretCode)}
                    </Typography>
                  </Box>
                </>
              )}

              <Typography variant="body1" textAlign="center">
                3. Enter the verification code from your authenticator app
              </Typography>
              <TextField
                autoFocus
                fullWidth
                label="Verification Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled={isLoading}
                error={Boolean(error)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
              {error && (
                <Typography color="error" textAlign="center">
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={!code || isLoading}
              >
                Verify
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={handleBackToLogin}
                disabled={isLoading}
              >
                Back to Login
              </Button>
            </>
          ) : (
            <Typography variant="body1" textAlign="center" color="success.main">
              MFA setup successful! Redirecting...
            </Typography>
          )}
        </Box>
      </form>
    </LoginCard>
  );
};

export default SetupMFA;
