import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";
import AuthContext from "../../authContext";
import { clearErrorOnFocus } from "./util";

interface Props {
  onSubmit: (code: string, rememberMe: boolean) => Promise<void>;
}

const MFAForm: React.FC<Props> = ({ onSubmit }) => {
  const [code, setCode] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { isLoading, errorMessage, setErrorMessage } = useContext(AuthContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await onSubmit(code, rememberMe);
    } catch (error) {
      // Error will be handled by the auth provider
      console.error("MFA verification error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Typography variant="h6" textAlign="center" mb={4}>
        <strong>Enter verification code</strong>
      </Typography>
      <Box display="flex" flexDirection="column" gap={4} width="100%">
        <TextField
          autoFocus
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          onFocus={clearErrorOnFocus(setErrorMessage)}
          placeholder="Enter code"
          type="text"
          disabled={isLoading}
          error={Boolean(errorMessage)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
              disabled={isLoading}
            />
          }
          label="Remember me for 7 days"
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isLoading || !code}
        >
          {isLoading ? "Verifying..." : "Verify"}
        </Button>
        <FormError show={Boolean(errorMessage)} message={errorMessage} />
      </Box>
    </form>
  );
};

export default MFAForm;
