import { AuthMessage } from "../../util/copy";

export const loginButtonStyles = {
  color: "primary.main",
  textDecoration: "none",
  fontSize: 14,
  mt: 1,
  mb: 8,
  alignSelf: "start",
};

export const clearErrorOnFocus = (setError: (error: string) => void) => {
  return () => {
    setError("");
  };
};
