import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";

interface Props extends TypographyProps {
  message: string;
  show: boolean;
}

const FormError: React.FC<Props> = ({ message, show, ...rest }) => {
  const visibility = show ? "visible" : "hidden";

  return (
    <Typography
      color="error"
      variant="body2"
      mt={1}
      textAlign="center"
      sx={{ height: 40 }}
      visibility={visibility}
      {...rest}
    >
      {message}
    </Typography>
  );
};

export default FormError;
