import React, { useState, useContext } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";
import AuthContext from "../../authContext";
import { clearErrorOnFocus } from "./util";

interface Props {
  onSubmit: (email: string) => Promise<void>;
  onBackToLogin?: () => void;
}

const ResetPasswordForm: React.FC<Props> = ({ onSubmit, onBackToLogin }) => {
  const [email, setEmail] = useState("");
  const { isLoading, errorMessage, setErrorMessage } = useContext(AuthContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await onSubmit(email);
    } catch (error) {
      // Error will be handled by the auth provider
      console.error("Reset password error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Typography variant="h6" textAlign="center" mb={4}>
        <strong>Reset Password</strong>
      </Typography>
      <Box display="flex" flexDirection="column" gap={4} width="100%">
        <TextField
          autoFocus
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={clearErrorOnFocus(setErrorMessage)}
          placeholder="Email"
          type="email"
          disabled={isLoading}
          error={Boolean(errorMessage)}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isLoading || !email}
        >
          {isLoading ? "Sending reset email..." : "Send Reset Email"}
        </Button>
        {onBackToLogin && (
          <Button onClick={onBackToLogin} disabled={isLoading} sx={{ mt: 2 }}>
            Back to Login
          </Button>
        )}
        <FormError show={Boolean(errorMessage)} message={errorMessage} />
      </Box>
    </form>
  );
};

export default ResetPasswordForm;
