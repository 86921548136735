import React, { useState, useContext } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { AuthMessage } from "../../util/copy";
import FormError from "../../components/FormError";
import AuthContext from "../../authContext";
import { clearErrorOnFocus } from "./util";
import LoginCard from "./LoginCard";

export interface InputData {
  firstName: string;
  lastName: string;
  department: string;
  title: string;
}

interface Props {
  onSubmit: (data: InputData) => Promise<void>;
}

const FirstTimersForm: React.FC<Props> = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [department, setDepartment] = useState("");
  const [title, setTitle] = useState("");
  const { isLoading, errorMessage, setErrorMessage } = useContext(AuthContext);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await onSubmit({ firstName, lastName, department, title });
    } catch (error) {
      // Error will be handled by the auth provider
      console.error("First timers form error:", error);
    }
  };

  return (
    <LoginCard isLarge>
      <form onSubmit={handleSubmit}>
        <Typography textAlign="center" my={8}>
          Please provide some additional information to get started
        </Typography>
        <Box
          mt={6}
          display="flex"
          flexDirection="column"
          rowGap={12}
          width="100%"
        >
          <Box display="flex" columnGap={6}>
            <TextField
              label="First Name"
              autoComplete="firstName"
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              onFocus={clearErrorOnFocus(setErrorMessage)}
              value={firstName}
              placeholder="First Name"
              type="text"
              fullWidth
              disabled={isLoading}
              error={Boolean(errorMessage)}
            />
            <TextField
              label="Last Name"
              autoComplete="lastName"
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
              onFocus={clearErrorOnFocus(setErrorMessage)}
              value={lastName}
              placeholder="Last Name"
              type="text"
              fullWidth
              disabled={isLoading}
              error={Boolean(errorMessage)}
            />
          </Box>
          <Box display="flex" columnGap={6} mb={8}>
            <TextField
              label="Title (optional)"
              autoComplete="title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              onFocus={clearErrorOnFocus(setErrorMessage)}
              value={title}
              placeholder="Title"
              type="text"
              fullWidth
              disabled={isLoading}
            />
            <TextField
              label="Department (optional)"
              autoComplete="department"
              name="department"
              onChange={(e) => setDepartment(e.target.value)}
              onFocus={clearErrorOnFocus(setErrorMessage)}
              value={department}
              placeholder="Department"
              type="text"
              fullWidth
              disabled={isLoading}
            />
          </Box>
        </Box>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isLoading || !firstName || !lastName}
        >
          Activate Account
        </Button>
        <FormError show={Boolean(errorMessage)} message={errorMessage} />
      </form>
    </LoginCard>
  );
};

export default FirstTimersForm;
