import { Amplify, Hub, Auth } from "aws-amplify";
import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import ApolloAppSyncClient from "./ApolloAppsyncClient";
import { ApolloProvider } from "@apollo/client";
import theme from "./theme";
import AuthProvider, { authConfig } from "./AuthProvider";
import View from "./View";
import ErrorBoundary from "./components/ErrorBoundary";
import LoggedInErrorBoundary from "./components/LoggedInErrorBoundary";
import OccuRouter from "./OccuRouter";
import { OccuUser } from "./interfaces";
import ViewProvider from "./ViewProvider";
import TreeProvider from "./TreeProvider";
import AuthContext from "./authContext";
import TrackerProvider from "./TrackerProvider";
import RequireMFACheck from "./components/RequireMFACheck";

LicenseInfo.setLicenseKey(
  "6c51664a97ccbf82e4133d8ddf0904e9Tz0xMDYyMjYsRT0xNzY4NzgwNzk5MDAwLFM9cHJvLExNPXBlcnBldHVhbCxQVj1RMy0yMDI0LEtWPTI="
);

// Function to configure Amplify based on the user type
const configureAmplify = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const user = searchParams.get("user");
  const code = searchParams.get("code");
  if (user && code) {
    Amplify.configure({
      Auth: {
        authenticationFlowType: "USER_PASSWORD_AUTH",
        region: "us-west-2",
        userPoolId: process.env.USER_POOL_ID,
        userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
      },
    });
    return;
  }
  Amplify.configure({
    Auth: {
      ...authConfig,
      userPoolWebClientId: process.env.OKTA_USER_POOL_CLIENT_ID,
    },
  });
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const clientId = cognitoUser.pool.clientId;
    const isOktaUser = clientId === process.env.OKTA_USER_POOL_CLIENT_ID;
    const config = {
      Auth: {
        ...authConfig,
        userPoolWebClientId: isOktaUser
          ? process.env.OKTA_USER_POOL_CLIENT_ID
          : process.env.USER_POOL_CLIENT_ID,
      },
    };

    Amplify.configure(config);
  } catch (error) {
    const config = {
      Auth: {
        ...authConfig,
        userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
      },
    };
    Amplify.configure(config);
  }
};

configureAmplify();

Hub.listen("auth", ({ payload: { event, data } }) => {
  switch (event) {
    case "signIn":
      // Handle successful sign in
      break;
    case "signIn_failure":
      console.error("Sign in failure:", data);
      break;
    case "cognitoHostedUI":
      // Handle hosted UI
      break;
    case "tokenRefresh":
      console.log("Token refresh succeeded");
      break;
    case "tokenRefresh_failure":
      window.location.reload();
      break;
  }
});

export default function App() {
  return (
    <TrackerProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <SnackbarProvider
              autoHideDuration={4000}
              maxSnack={6}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Router>
                <ErrorBoundary>
                  <ApolloProvider client={ApolloAppSyncClient}>
                    <AuthProvider>
                      <RequireMFACheck>
                        <LoggedInErrorBoundary>
                          <TreeProvider>
                            <ViewProvider>
                              <View>
                                <AuthContext.Consumer>
                                  {(authContext) => {
                                    return (
                                      <OccuRouter
                                        user={authContext.occuUser as OccuUser}
                                      />
                                    );
                                  }}
                                </AuthContext.Consumer>
                              </View>
                            </ViewProvider>
                          </TreeProvider>
                        </LoggedInErrorBoundary>
                      </RequireMFACheck>
                    </AuthProvider>
                  </ApolloProvider>
                </ErrorBoundary>
              </Router>
            </SnackbarProvider>
          </LocalizationProvider>
        </CssBaseline>
      </ThemeProvider>
    </TrackerProvider>
  );
}
