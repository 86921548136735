import { DateTime } from "luxon";
import Button, { ButtonProps } from "@mui/material/Button";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";
import { DialogProps } from "@mui/material/Dialog";

export enum UserRole {
  SuperAdmin = "owner",
  Admin = "admin",
  User = "user",
  Setup = "setup",
}

export enum UserStatus {
  All = "ALL",
  Active = "ACTIVE",
  InActive = "INACTIVE",
  Pending = "PENDING",
}

export enum MultiSelectDropdown {
  AllValue = "all",
}

export enum AppSyncError {
  UserExists = "USER_EXISTS",
  UserDoesNotExist = "User does not exist",
  SensorAlreadyPaired = "Already Paired",
  UniqueConstraint = "UniqueConstraint",
}

export enum SpaceType {
  Zone = "ZONE",
  Group = "GROUP",
  Filtration = "FILTRATION",
}

export interface User {
  id: string;
  fullName: string;
  email: string;
  role: string;
  status: UserStatus;
  lastActive: string;
}

export enum Products {
  Occupancy = "Occupancy",
  Visitorship = "Visitorship",
  Dwelltime = "Dwell Time",
}

export interface SpaceNode {
  spaceStatus: SpaceStatus;
  zoneStatus: ZoneStatus;
  parentId: number;
  id: number;
  CustomerID: number;
  name: string;
  type: SpaceType;
  children: SpaceNode[];
  totalLiveZones: number;
  totalZones: number;
  activeDate: string;
  capacity: number;
  customerName: string;
  visitorship: boolean;
  occupancy: boolean;
  dwelltime: boolean;
  occupancyActiveDate: string;
  visitorshipActiveDate: string;
  dwelltimeActiveDate: string;
}
export interface SpaceNodeByIdMap {
  [index: string]: SpaceNode;
}

export enum AuthenticationType {
  NORMAL = "NORMAL",
  OKTA = "OKTA",
}

export interface OccuUser {
  id: string;
  customerId: number;
  fullName: string;
  department: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  status: UserStatus;
  lastActive: string;
  customerName: string;
  cognitoCreateDate: string;
  authentication: AuthenticationType;
  customerMFA: boolean;
}

export interface UserWithLocations extends OccuUser {
  userLocations: {
    locations: SpaceNode[];
    totalPages: number;
  };
}

export enum SpaceStatus {
  Live = "ACTIVE",
  Inactive = "INACTIVE",
  Setup = "SETUP",
}

export enum ZoneStatus {
  Live = "ACTIVE",
  Inactive = "INACTIVE",
  Setup = "SETUP",
  Calibration = "CALIBRATION",
  Training = "TRAINING",
  Inreview = "REVIEW",
}

export enum SensorStatus {
  Installed = "ACTIVE",
  InReview = "REVIEW",
  Reset = "RESET",
  Replace = "REPLACE",
  Empty = "EMPTY",
  Deleted = "DELETED",
}

export interface Location {
  id: string;
  name: string;
}

export interface ChildSpace {
  capacity: number;
  id: number;
  name: string;
  notificationCount: number;
  sensorsNeedingInstall: number;
  zonesNeedingHeadcounts: number;
}

export enum DaysOfTheWeek {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum ShortWeekday {
  Mon = "Mon",
  Tue = "Tue",
  Wed = "Wed",
  Thu = "Thu",
  Fri = "Fri",
  Sat = "Sat",
  Sun = "Sun",
}

export enum AnalyticsfilterVariant {
  Filter,
  Dialog,
}

export enum OccuSnackbarVariant {
  Edit,
  Undo,
}

export enum PageTitles {
  Analytics = "Analytics",
  LiveData = "Live Data",
  Spaces = "Spaces",
  UserManagement = "User Management",
  MyAccount = "My Account",
}

export interface RightPanel {
  capacity: number;
  lastUpdated: string;
  liveZoneTotal: number;
  name: string;
  sensorTotal: number;
  status: SpaceStatus;
  type: SpaceType;
  zoneTotal: number;
  liveData: {
    id: number;
    occupancy: number;
    percent: number;
    spaceName: string;
    spaceType: SpaceType;
  };
  floorPlans: FloorPlan[];
}

export interface Subzone {
  placement: string;
  status: SensorStatus;
  sensorId?: string;
  id?: string;
  subzoneId: number;
  inventoryId?: number;
  zone: {
    name: string;
    floorPlans: FloorPlan[] | null;
  };
}

export interface FloorPlan {
  id: number;
  name?: string;
  description?: string;
  customerId: number;
  zoneId: number;
  filename: string;
  status: string;
  createdDate: string;
  url: string;
}

export interface Image {
  url: string;
  name?: string;
}

export interface DialogState {
  isOpen: boolean;
  errorMessage: string;
  isLoading: boolean;
}

export interface SimpleDialogProps {
  children?: React.ReactNode;
  ariaLabel: string;
  errorMessage: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: AnyFn;
  title: string;
  maxWidth?: DialogProps["maxWidth"];
  buttonProps?: ButtonProps | LoadingButtonProps;
  ButtonComponent?: typeof Button | typeof LoadingButton;
  errorPositionLeft?: boolean;
}

export interface Map {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface BooleanMap {
  [key: string]: boolean;
}
export interface InputField {
  onChange?: AnyFn;
  error?: string;
  value?: string | number | DateTime | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFn = (any: any) => void;

export type ReactChangeEventHandler = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

export function emptyFn() {
  return null;
}

export type PageChangeHandler = (
  event: React.ChangeEvent<unknown>,
  value: number
) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyVal = any;

export interface HourRuleQueryItem {
  customerId: number;
  id: number;
  name: string;
  source: string;
}

export interface GetCustomerHourRulesQueryResult {
  customerHourRules: HourRuleQueryItem[];
  globalHourRules: HourRuleQueryItem[];
}
