import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import LoginCard from "./LoginCard";
import PasswordStrengthStatus from "../../components/PasswordStrengthStatus";
import { loginButtonStyles } from "./util";
import FormError from "../../components/FormError";
import {
  eightCharRegex,
  oneUppercaseRegex,
  oneDigitRegex,
} from "../../AuthProvider";
import { AuthMessage } from "../../util/copy";
import AuthContext from "../../authContext";
import { clearErrorOnFocus } from "./util";

interface Props {
  network: { isLoading: boolean; errorMessage: string };
  onInputChange: ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
  onBackToLogin?: () => void;
  onSubmit: (newPassword: string) => Promise<void>;
  onInputFocus: () => void;
  hideBackToLogin?: boolean;
  submitButtonText: string;
  input: { newPassword: string; confirmNewPassword: string };
}

const NewPasswordForm: React.FC<Props> = ({
  onInputChange,
  onBackToLogin,
  onSubmit,
  onInputFocus,
  hideBackToLogin,
  submitButtonText,
  network: { isLoading, errorMessage },
  input: { newPassword, confirmNewPassword },
}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { setErrorMessage } = useContext(AuthContext);

  const charError = Boolean(!eightCharRegex.test(newPassword));
  const uppercaseError = Boolean(!oneUppercaseRegex.test(newPassword));
  const digitError = Boolean(!oneDigitRegex.test(newPassword));

  const passwordsDoNotMatchError =
    confirmNewPassword.length > 1 && newPassword !== confirmNewPassword;

  const isSubmitDisabled =
    isLoading ||
    !newPassword ||
    !confirmNewPassword ||
    passwordsDoNotMatchError ||
    charError ||
    uppercaseError ||
    digitError;

  const handleBackToLoginClick = () => {
    navigate("/", { replace: true });
    onBackToLogin && onBackToLogin();
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setErrorMessage(AuthMessage.PasswordsDoNotMatch);
      return;
    }
    try {
      await onSubmit(newPassword);
    } catch (error) {
      // Error will be handled by the auth provider
      console.error("New password submission error:", error);
    }
  };

  return (
    <LoginCard>
      <form style={{ width: "100%" }} onSubmit={handleSubmit}>
        <Typography textAlign="center" mt={8} mb={4}>
          Set the password for your Occuspace account
        </Typography>
        <Box display="flex" flexDirection="column" rowGap={2} width="100%">
          <TextField
            sx={{
              // Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq)
              display: "none",
            }}
            autoComplete="email"
            name="email"
            placeholder="Email"
            type="text"
            fullWidth
          />
          <TextField
            autoFocus
            autoComplete="new-password"
            name="newPassword"
            onChange={onInputChange}
            onFocus={clearErrorOnFocus(setErrorMessage)}
            value={newPassword}
            placeholder="New Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            disabled={isLoading}
            error={Boolean(errorMessage)}
          />
          <PasswordStrengthStatus
            text="Must be at least 8 characters"
            error={charError}
          />
          <PasswordStrengthStatus
            text="Must have at least one uppercase letter"
            error={uppercaseError}
          />
          <PasswordStrengthStatus
            text="Must have at least one number"
            error={digitError}
          />
          <TextField
            sx={{ mt: 2 }}
            autoComplete="confirm-new-password"
            name="confirmNewPassword"
            onChange={onInputChange}
            onFocus={clearErrorOnFocus(setErrorMessage)}
            value={confirmNewPassword}
            placeholder="Confirm Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            disabled={isLoading}
            error={Boolean(errorMessage)}
          />
          {passwordsDoNotMatchError && (
            <PasswordStrengthStatus
              text={AuthMessage.PasswordsDoNotMatch}
              error={passwordsDoNotMatchError}
            />
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={showPassword}
                onChange={(e) => setShowPassword(e.target.checked)}
                disabled={isLoading}
              />
            }
            label="Show password"
          />
        </Box>
        {!hideBackToLogin && onBackToLogin && (
          <Button
            sx={loginButtonStyles}
            onClick={handleBackToLoginClick}
            disabled={isLoading}
          >
            Back to Login
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={isSubmitDisabled}
          sx={{ mt: 8 }}
        >
          {submitButtonText}
        </Button>
        <FormError show={Boolean(errorMessage)} message={errorMessage} />
      </form>
    </LoginCard>
  );
};

export default NewPasswordForm;
